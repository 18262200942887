import { theme } from './components/Theme';
import qs from 'qs';

export const toRem = pixels => {
  return `${pixels / theme.fontSizeBase}rem`;
};

// Removes the layers of value from the props
// This returns a new changed props
export const cleanProps = props => {
  const objectMap = (obj, fn) =>
    Object.fromEntries(
      Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)])
    );

  return objectMap(props, value => {
    if (value === null) return null;
    if (typeof value === 'object' && 'value' in value) {
      if (value.value === null) return null;
      if (typeof value.value === 'object' && 'value' in value.value) {
        return value.value.value;
      }
      return value.value;
    }
    return value;
  });
};

// Return saved amout for products with discount
// Price constructed with product and variant const price = useDynamicPrice(product, variant) with Jetshop hook
export const getSavedAmount = price => {
  const currentPrice = price.price.incVat;
  const previousPrice = price.previousPrice.incVat;
  const save = previousPrice - currentPrice;
  return save;
};

export const getSearchParamsBasedOnCategoryId = ({ id }) => {
  const searchParams =
    typeof window !== 'undefined' ? window.location.search : '';

  const replacer = (match, p1, p2, p3, offset, string) => `${p1}${id}${p3}`;
  const searchParamsWithUpdatedCategoryIds = decodeURIComponent(
    searchParams
  ).replace(/(\[)([0-9]{1,4})(:)/g, replacer);

  return searchParamsWithUpdatedCategoryIds;
};

export const getFilterQueryString = (
  id = 2,
  manufacturer,
  model,
  fuel,
  modelYear
) => {
  if (!manufacturer && !model) return '';

  const list = {};

  if (fuel) {
    list[`${id}:fuel`] = [fuel];
  }
  if (modelYear) {
    list[`${id}:modelYear`] = [modelYear];
  }

  const queryString = `${qs.stringify(
    {
      multiList: {
        [`${id}:fits-car`]: [manufacturer?.value, model?.value || '']
      },
      list
    },
    { encode: false }
  )}`;

  return queryString;
};
