import React, { useState, useEffect, useRef } from 'react';
import { matchPath, useLocation } from 'react-router';
import { styled } from 'linaria/react';
import { theme } from '../../../Theme';
import AnimateHeight from 'react-animate-height';
import { css } from 'linaria';
import { Link } from 'react-router-dom';

// Limit the menu to our global MaxWidth
const MaxWidth = styled('div')`
  min-height: 320px;
  flex-direction: row;
  justify-content: space-between;
  background-color: #ffffff;

  a {
    display: block;
  }

  ${theme.below.lg} {
    flex-wrap: wrap;
  }
`;

const Wrapper = styled('section')`
  position: absolute;
  right: 0;
  left: 0;
  overflow: hidden;
  z-index: 999;
  will-change: height;
  transition: height 0.3s ease-in-out;
  width: 100%;
`;

const ContentWrapper = styled('div')`
  ${theme.below.lg} {
    margin-left: 10px;
  }
`;

const InnerContentWrapper = styled('div')`
  margin: 40px;
`;

const Level2Style = css`
  ul {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    margin-left: 40px;

    li {
      margin-top: 5px;
      margin-bottom: 0.3rem;
      margin-right: 30px;

      a {
        color: #1a202d;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 28px;
        text-decoration: none;
        display: flex;
        align-items: center;
        margin-top: 0px;
        :hover {
          text-decoration: underline;
        }
        svg {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
      &.active {
        a {
          color: #1a202d;
        }
      }
    }
  }
`;

const alphabeticalGroupContainer = css`
  display: flex;
  align-items: center;
  border-top: 0.5px solid lightgray;
  border-bottom: 0.5px solid lightgray;

  h3 {
    font-size: 3rem;
    font-weight: 400;
  }
`;

const ShowAllCategoriesButton = styled('button')`
  color: white !important;
  background: #1a202d !important;
  font-size: 1rem;
  font-family: 'Barlow';
  font-weight: 700;
  text-transform: uppercase;
  line-height: 25.6px;
  word-wrap: break-word;
  background-color: #1a202d;
  padding: 16px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 32px;
`;

const AlphabeticalLetter = styled('span')`
  margin-right: 10px;
  font-size: 18px;
  cursor: pointer;
`;
const generateAlphabet = () => {
  return [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'Å',
    'Ä',
    'Ö'
  ];
};

const SubMenuWrapperAlt = ({ pose, activeCategory, closeNav }) => {
  const [activeSubcategory, setActiveSubcategory] = useState(null);
  const [alphabeticalCategories, setAlphabeticalCategories] = useState({});
  const location = useLocation();
  const alphabeticalRef = useRef(null);

  // Generate alphabet
  const alphabet = generateAlphabet();

  useEffect(() => {
    let subCategoryInRoute = null;

    if (
      activeCategory &&
      activeCategory?.subcategories &&
      activeCategory?.subcategories?.length > 0
    ) {
      // Sort the subcategories alphabetically
      const sortedSubcategories = [...activeCategory.subcategories].sort(
        (a, b) => {
          return a.name.localeCompare(b.name);
        }
      );

      subCategoryInRoute = sortedSubcategories.find(cat => {
        return matchPath(location.pathname, {
          path: cat?.primaryRoute?.path,
          strict: false,
          sensitive: false
        });
      });
    }

    setActiveSubcategory(subCategoryInRoute);
  }, [activeCategory, location.pathname]);

  // New useEffect hook for categorizing items alphabetically
  useEffect(() => {
    if (activeCategory && activeCategory?.subcategories?.length > 0) {
      const sortedCategories = activeCategory.subcategories.reduce(
        (acc, curr) => {
          const firstLetter = curr.name[0].toUpperCase();
          if (!acc[firstLetter]) acc[firstLetter] = [];
          acc[firstLetter].push(curr);
          return acc;
        },
        {}
      );

      setAlphabeticalCategories(sortedCategories);
    }
  }, [activeCategory]);

  const scrollToLetter = letter => {
    if (alphabeticalRef.current) {
      const letterContainer = document.getElementById(letter);
      if (letterContainer) {
        letterContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };

  return (
    <Wrapper>
      <AnimateHeight height={pose === 'open' ? 'auto' : 0} duration={350}>
        <MaxWidth>
          <ContentWrapper>
            <InnerContentWrapper>
              {alphabet.map(letter => (
                <AlphabeticalLetter
                  key={letter}
                  onClick={() => scrollToLetter(letter)}
                  style={{
                    color: alphabeticalCategories[letter] ? '#1a202d' : '#ccc',
                    cursor: alphabeticalCategories[letter]
                      ? 'pointer'
                      : 'default'
                  }}
                >
                  {letter}
                </AlphabeticalLetter>
              ))}
              <div ref={alphabeticalRef}>
                <div className={Level2Style}>
                  {Object.entries(alphabeticalCategories)
                    ?.sort(([letterA], [letterB]) =>
                      letterA.localeCompare(letterB)
                    ) // Sort the entries by letter
                    ?.map(([letter, categories]) => (
                      <div
                        id={letter}
                        className={alphabeticalGroupContainer}
                        key={letter}
                      >
                        <h3>{letter}</h3>
                        <ul>
                          {categories.map(cat => (
                            <li key={cat.id}>
                              <Link
                                to={cat.primaryRoute.path}
                                onClick={closeNav}
                              >
                                {cat.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                </div>
              </div>
              <Link to="/all-categories" onClick={closeNav}>
                <ShowAllCategoriesButton>
                  Se alla produkter
                </ShowAllCategoriesButton>
              </Link>
            </InnerContentWrapper>
          </ContentWrapper>
        </MaxWidth>
      </AnimateHeight>
    </Wrapper>
  );
};

export default SubMenuWrapperAlt;
