import React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import { useIntl } from '@jetshop/intl';
import { Above } from '@jetshop/ui/Breakpoints';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { FlyoutTarget } from '@jetshop/ui/Modal/Flyout';
import { Price } from '@jetshop/ui/Price';
import { useChannelSettings } from '@jetshop/core/hooks/Channels/useChannelSettings';
import { Currency } from '@jetshop/ui/Price/Currency';
import { theme } from '../Theme';
import { baseStyles } from '../ui/Button';
import CartItem from './CartItem';
import FreeShipping from './FreeShipping';
import cartQuery from './queries/cartQuery.gql';

const Flyout = styled('div')`
  background: white;
  color: ${theme.colors.black};
  ${theme.above.sm} {
    position: absolute;
    z-index: 999;
    right: 0;
    top: 3.5rem;
    width: 25rem;
    min-width: 19rem;
    max-width: 100%;
  }
`;

const LightText = styled('span')`
  color: #828282;
  font-size: 1.1rem;
  font-weight: 600;
  color: black;
  font-family: 'Barlow';
`;

const Header = styled('header')`
  text-align: flex-start;
  h2 {
    font-weight: 600;
  }
  ${LightText} {
    display: block;
  }
  padding: 1rem;
  border-bottom: 1px solid #e8e8e8;

  .total {
    display: flex;
    gap: 5px;
    font-weight: 500;
    font-size: 0.875rem;
  }

  .vat {
    display: flex;
    gap: 5px;
    font-weight: 500;
    font-size: 0.875rem;
  }
`;

const ItemCount = ({ count }) => {
  const t = useIntl();
  return (
    <LightText>
      {/* {t(
        `{
        count, plural,
        =0 {}
        one {1 item}
        other {{count} items}
            }`,
        { count }
      )} */}
      {count} produkter i din varukorg{' '}
    </LightText>
  );
};

const CartItems = styled('section')`
  background: #fafafa;
  max-height: 500px;
  overflow-y: auto;

  ${theme.below.sm} {
    max-height: 270px;
  }
`;

const Summary = styled('section')`
  background: #fff;
  padding: 1rem;

  a {
    width: 100%;
    color: white;
    :hover {
      color: white;
    }
  }

  h2 {
    font-weight: bold;
    font-size: 1.2rem;
  }

  .discounts {
    margin-bottom: 1em;
    h2 {
      margin-bottom: 1em;
    }
  }

  .cart-total {
    display: flex;
    padding-top: 1em;

    > * + * {
      margin-top: 0.5em;
    }

    .shipping,
    .total,
    .vat {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .total {
      display: flex !important;
      font-size: 1.25rem;
      font-weight: 600;
      color: ${theme.colors.black};
    }
  }
`;

const Flex = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const Checkout = styled.a`
  background-color: #6cc400;
  border-color: ${theme.colors.black};
  color: black !important;
  font-size: 16px;
  font-weight: 700;
  padding: 18px 52px;
  display: inline-block;
  width: 100%;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.1px;
  &&:hover {
    color: white;
    text-decoration: none;
  }
`;

const modelStyles = css`
  padding-left: 12px;

  p:first-child {
    font-family: 'Barlow';
    font-size: 1rem;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 1px;
    color: black;
  }

  p:nth-child(2) {
    font-family: 'Barlow';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: 1px;
    color: black;
  }
`;

const fitsCarContainer = css`
  margin-left: 10px;

  p:first-child {
    font-size: 1rem;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 1px;
  }
`;

const CartItemWithFitsCar = ({ item, result }) => {
  if (!item) return null;

  const fitsCar = item?.product?.customFields?.find(
    field => field.key === 'fits-car'
  );

  return (
    <div>
      {fitsCar && (
        <div className={fitsCarContainer}>
          {/* {' '} */}
          {/* <p>Delar till:</p> */}
          <p>
            {fitsCar?.items
              ?.map(item => item.value.replace('->>', ''))
              .join(' ')}
          </p>
        </div>
      )}
      <CartItem item={item} result={result} />
    </div>
  );
};

const CartFlyoutView = ({ result, modal, ...rest }) => {
  const items = result?.data?.cart?.items ?? [];
  const itemCount = result?.data?.cart?.totalQuantity ?? 0;
  const licensePlate =
    typeof window !== 'undefined' ? localStorage.getItem('licensePlate') : '';
  const checkoutUrl = `${result?.data?.cart?.externalCheckoutUrl}${
    licensePlate ? `&Comment1=${licensePlate}` : ''
  }`;
  const discounts = result?.data?.cart?.aggregatedDiscounts ?? [];
  const track = useTracker();
  const { pricesIncVat } = useChannelSettings();
  const t = useIntl();

  if (items.length === 0) {
    return (
      <Flyout {...rest}>
        <div style={{ padding: '2em' }}>{t('No items in cart.')}</div>
      </Flyout>
    );
  }

  return (
    <Flyout {...rest}>
      <Header>
        {/* <h2>{t('Your cart')}</h2> */}
        <ItemCount count={itemCount} />
        <div className="cart-total">
          <FreeShipping
            className="shipping"
            cartTotal={result.data.cart.productTotal}
          />

          <div className="total">
            <label>
              {pricesIncVat ? t('Total incl. VAT') : t('Total excl. VAT')}
            </label>
            <Price price={result.data.cart.productTotal} />
          </div>

          <VAT
            className="vat"
            total={result.data.cart.productTotal}
            pricesIncVat={pricesIncVat}
          />
        </div>
      </Header>
      <Summary>
        {checkoutUrl && (
          <Checkout
            data-testid="checkout-button"
            href={checkoutUrl}
            onClick={event => {
              track(
                trackCartCheckoutEvent({
                  cart: result.data.cart,
                  callback: () => {
                    window.location = checkoutUrl;
                  }
                })
              );
            }}
          >
            {t('Check out')}
          </Checkout>
        )}
      </Summary>
      {/* <CartItems>
        {items.map(item => (
          <CartItem item={item} key={item.id} />
        ))}
      </CartItems> */}
      <CartItems>
        {items?.map(item => (
          <CartItemWithFitsCar key={item.id} item={item} result={result} />
        ))}
      </CartItems>
      <Summary>
        {discounts.length > 0 && (
          <div className="discounts">
            <h2>{t('Cart total')}</h2>
            <Flex>
              <label>{t('Order value')}</label>
              <Price price={result.data.cart.productPreviousTotal} />
            </Flex>
            <h2 style={{ marginTop: '1em' }}>{t('Discounts')}</h2>
            {discounts?.map(discount => {
              return (
                <Flex key={discount.name} style={{ marginTop: '1em' }}>
                  <label>{discount.name}</label>
                  <Price price={discount.value} style={{ display: 'inline' }} />
                </Flex>
              );
            })}
          </div>
        )}
        {/* <div className="cart-total">
          <FreeShipping
            className="shipping"
            cartTotal={result.data.cart.productTotal}
          />

          <div className="total">
            <label>
              {pricesIncVat ? t('Total incl. VAT') : t('Total excl. VAT')}
            </label>
            <Price price={result.data.cart.productTotal} />
          </div>

          <VAT
            className="vat"
            total={result.data.cart.productTotal}
            pricesIncVat={pricesIncVat}
          />
        </div> */}
      </Summary>
    </Flyout>
  );
};

function VAT({ total, pricesIncVat, ...rest }) {
  const t = useIntl();
  return (
    <div {...rest}>
      <label>{pricesIncVat ? t('of which VAT') : t('+ VAT')}</label>
      <Currency value={total.vat} />
    </div>
  );
}

const CartFlyout = props => (
  // Wrap the flyout with the needed providers
  <CartProvider query={cartQuery}>
    {result =>
      result.data && result.data.cart ? (
        <Above breakpoint="lg">
          {matches =>
            matches ? (
              <FlyoutTarget id="cart-flyout">
                {flyout => (
                  <CartFlyoutView modal={flyout} result={result} {...props} />
                )}
              </FlyoutTarget>
            ) : (
              <DrawerTarget id="cart-drawer">
                {drawer => (
                  <Drawer isOpen={drawer.isOpen} right>
                    <CartFlyoutView modal={drawer} result={result} {...props} />
                  </Drawer>
                )}
              </DrawerTarget>
            )
          }
        </Above>
      ) : null
    }
  </CartProvider>
);

export default CartFlyout;
