import React from 'react';
import { css } from 'linaria';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import t from '@jetshop/intl';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import { ReactComponent as Cart } from '../../assets/svg/Cart.svg';
import Badge from '../ui/Badge';
import cartQuery from './queries/cartQuery.gql';
import { FavouriteCountMobile } from '../ProductList/FavouriteCountMobile';

const Button = styled('button')`
  padding: 0;
  background: transparent;
  color: inherit;
  border: 0;
  outline: none;

  svg:hover {
    path {
      fill: ${theme.colors.abdGreen} !important;
    }
  }
`;

const InnerWrapper = styled('div')`
  display: flex;
  align-items: center;

  .quantity {
    margin-left: 5px;
    font-size: 0.875rem;
  }
`;

const cartButtonWrapper = css`
  position: relative;
  background-color: #6cc400;
  width: 147px;
  height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 20px 14px;
  gap: 10px;
  cursor: pointer;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 3px;
  }

  :hover,
  :active {
  }

  button {
    font-weight: 700 !important;
    text-transform: uppercase;
  }

  .badge {
    position: absolute;
    pointer-events: none;
    color: white;
    font-size: 8px;
  }
`;

const FavouritesAndCartButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;

const cartIconButtonWrapper = css``;

function CartButton({ target, itemsInCart, className }) {
  return (
    <>
      <Above breakpoint="lg">
        <div
          className={cartButtonWrapper}
          onClick={target.isOpen ? target.hideTarget : target.showTarget}
        >
          <Button className={className} data-testid="cart-button">
            {
              <InnerWrapper>
                <span>{t('To the checkout')}</span>
                {itemsInCart > 0 && (
                  <span className="quantity">{itemsInCart}</span>
                )}
              </InnerWrapper>
            }
          </Button>
        </div>
      </Above>
      <Below breakpoint="lg">
        <div className={cartIconButtonWrapper}>
          <Button
            className={className}
            data-testid="cart-button"
            onClick={target.isOpen ? target.hideTarget : target.showTarget}
          >
            <Cart />
            {itemsInCart > 0 && <Badge text={itemsInCart} />}
          </Button>
        </div>
      </Below>
    </>
  );
}

function CartButtonFlyout({ className }) {
  return (
    <CartProvider query={cartQuery}>
      {result => {
        const itemsInCart = result?.data?.cart?.totalQuantity || 0;

        // Drawer on mobile, flyout on desktop
        return (
          <Above breakpoint="lg">
            {matches =>
              matches ? (
                <FlyoutTrigger id="cart-flyout">
                  {flyout => (
                    <CartButton
                      className={className}
                      target={flyout}
                      itemsInCart={itemsInCart}
                    />
                  )}
                </FlyoutTrigger>
              ) : (
                <FavouritesAndCartButtonContainer>
                  <FavouriteCountMobile />
                  <DrawerTrigger preventOverflow={true} id="cart-drawer">
                    {drawer => (
                      <CartButton
                        className={className}
                        target={drawer}
                        itemsInCart={itemsInCart}
                      />
                    )}
                  </DrawerTrigger>
                </FavouritesAndCartButtonContainer>
              )
            }
          </Above>
        );
      }}
    </CartProvider>
  );
}

export default CartButtonFlyout;
