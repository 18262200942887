import React from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../MaxWidth';
import { Link } from 'react-router-dom';
import { theme } from '../../Theme';
import { ReactComponent as Location } from '../../../assets/svg/Location.svg';
import { css } from 'linaria';
import useCleanProps from '../../../hooks/useCleanProps';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import t from '@jetshop/intl';
import { ReactComponent as LocationPin } from '../../../svg/LocationPin.svg';

const ContactBarUspWrapper = styled('div')`
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;

  ${theme.below.md} {
    padding: 5px 0;
  }

  ${theme.below.sm} {
    background: transparent;
  }
`;

const MaxWidthWrapper = styled(MaxWidth)``;

const Container = styled('div')`
  display: flex;
  justify-content: space-between;

  ${theme.below.sm} {
    flex-direction: column-reverse;
  }
`;

const FindStoreSection = styled('div')`
  display: flex;
  align-items: center;

  ${theme.below.lg} {
    display: none;
  }
`;

const locationPinStyles = css``;

const findStoreStyles = css`
  margin-left: 5px;
  font-family: 'Barlow';
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 22.4px;
  letter-spacing: 0.1px;
  color: black;
`;

const ContactSection = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 14px;
  font-weight: 500;
  line-height: 14px;

  ${theme.below.sm} {
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 0;
    margin-top: 1rem;
  }
`;

const PhoneWrapper = styled('div')`
  display: flex;
  ${theme.above.lg} {
    margin-left: 48px;
  }
`;

const EmailWrapper = styled('div')`
  display: flex;
  ${theme.above.sm} {
    margin-left: 48px;
  }
`;

const Label = styled('p')`
  ${theme.above.sm} {
    font-weight: 700;
  }
`;

const Value = styled('a')`
  margin-left: 4px;
  color: black;
`;
const LinkWrapper = styled('div')``;

const InternalLink = styled(Link)`
  color: black;
  font-size: 14px;
  font-weight: 700;
`;

const ExternalLink = styled('a')`
  color: black;
  font-size: 14px;
  font-weight: 700;
`;

const location = css`
  margin-right: 8.72px;
`;

const ContactBarUsp = props => {
  const { routes } = useShopConfig();

  const {
    phoneLabel,
    phoneValue,
    emailLabel,
    emailValue,
    businessCustomerLinkLabel,
    internalLinkUrl,
    externalLinkUrl
  } = useCleanProps(props);

  const telLink = `tel:${phoneValue}`;
  const mailtoLink = `mailto:${emailValue}`;

  return (
    <ContactBarUspWrapper>
      <MaxWidthWrapper>
        <Container>
          <ContactSection>
            <FindStoreSection>
              <LocationPin className={locationPinStyles} />
              <Link to={routes.stores.path} className={findStoreStyles}>
                {t('Find Store')}
              </Link>
            </FindStoreSection>
            <PhoneWrapper>
              <Label>{phoneLabel}</Label>
              <Value href={telLink}>{phoneValue}</Value>
            </PhoneWrapper>
            <EmailWrapper>
              <Label>{emailLabel}</Label>
              <Value href={mailtoLink}>{emailValue}</Value>
            </EmailWrapper>
          </ContactSection>
          <LinkWrapper>
            <Location className={location} />
            {externalLinkUrl ? (
              <ExternalLink
                href={externalLinkUrl}
                target={'_blank'}
                rel="noreferrer"
              >
                {businessCustomerLinkLabel}
              </ExternalLink>
            ) : (
              <InternalLink to={internalLinkUrl}>
                {businessCustomerLinkLabel}
              </InternalLink>
            )}
          </LinkWrapper>
        </Container>
      </MaxWidthWrapper>
    </ContactBarUspWrapper>
  );
};

export default ContactBarUsp;
