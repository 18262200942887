import React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { useIntl } from '@jetshop/intl';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { Link } from 'react-router-dom';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { Below } from '@jetshop/ui/Breakpoints';
import CloseButton from '../../ui/CloseButton';
import MobileCategories from './Categories/MobileCategories';
import { ReactComponent as LocationPin } from '../../../svg/LocationPin.svg';
import ContactBarUsp from './ContactBarUsp';

const StyledCloseButton = styled(CloseButton)`
  margin-left: 0;
  margin-right: auto;
`;

const Title = styled('h1')`
  text-align: center;
  position: absolute;
  font-size: 1.125rem;
`;

const NavBarHeader = styled('div')`
  position: relative;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  height: 45px;
`;

const Scroll = styled('div')`
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  flex: 1 1 auto;
`;

const SecondaryMenu = styled.ul`
  margin: 0;
  margin-top: 16px;
  padding: 0;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  flex: 0 0 auto;
`;

const SecondaryMenuItem = styled.li`
  list-style: none;
  display: block;
  padding: 1em 1em 0.9375em;
  a,
  a:active {
    text-decoration: none;
    color: black;
  }
  button {
    background: none;
    color: black;
  }
`;

const CustomLinkWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  font-family: 'Barlow';
  font-size: 1rem;
  font-weight: 500;
  line-height: 14px;
  color: black;
  padding-right: 4rem;
  text-decoration: none;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-bottom: 1rem;

  &:last-child {
    padding-right: 0;
  }

  &:hover {
    color: #ffffff !important;
    text-decoration: underline;
  }

  .customLinkStyles {
    margin-top: 20px;
    color: black;
    text-decoration: none;
  }

  .customLinkStyles:first-child {
    margin-top: 0;
  }
`;

const ShowAllCategoriesButton = styled('button')`
  color: white !important;
  background: #1a202d !important;
  font-size: 1rem;
  font-family: 'Barlow';
  font-weight: 700;
  text-transform: uppercase;
  line-height: 25.6px;
  word-wrap: break-word;
  background-color: #1a202d;
  padding: 10px;
  margin-top: 16px;
  margin-left: 16px;
`;

const innerContainer = css`
  margin-left: 1rem;

  a {
    font-family: 'Barlow';
    font-size: 1rem;
    color: black;
    text-decoration: none;
  }
`;

const locationPinStyles = css``;

const linkStyles = css`
  margin-left: 3px;
`;

export default function MobileMenu({ data, headerData }) {
  const { loggedIn } = useAuth();
  const { routes } = useShopConfig();
  const t = useIntl();

  if (!(data && data?.categories)) return null;

  const filteredCategories = data?.categories
    ?.map(category => {
      if (category.name === 'Bil') {
        return { ...category, name: t('Categories') };
      }
      return category;
    })
    .filter(category => category.name === t('Categories'));

  const customLinks = [
    { name: 'Köpvillkor', route: '/kopvillkor' },
    { name: 'Leverans', route: '/leverans' }
  ];

  const contactUspComponents = {
    CONTACTBARUSP: ContactBarUsp
  };

  return (
    <DrawerTarget id="menu-drawer">
      {drawer => (
        <Drawer isOpen={drawer.isOpen}>
          <NavBarHeader>
            <StyledCloseButton onClick={drawer.hideTarget} />
            <Title>{t('Menu')}</Title>
          </NavBarHeader>
          <Scroll>
            <MobileCategories
              categories={filteredCategories}
              closeMenu={drawer.hideTarget}
            />
            <Link to="/bil" onClick={drawer.hideTarget}>
              <ShowAllCategoriesButton>
                {t('Show all products')}
              </ShowAllCategoriesButton>
            </Link>
            {customLinks && (
              <CustomLinkWrapper>
                {customLinks?.map((link, index) => (
                  <Link
                    key={index}
                    onClick={drawer.hideTarget}
                    to={link?.route}
                    className="customLinkStyles"
                  >
                    {link?.name}
                  </Link>
                ))}
              </CustomLinkWrapper>
            )}
            <SecondaryMenu>
              <SecondaryMenuItem>
                <Link onClick={drawer.hideTarget} to={routes.myPages.path}>
                  {loggedIn ? t('My Pages') : t('Log in')}
                </Link>
              </SecondaryMenuItem>
              <div className={innerContainer}>
                <a href="/favourites" alt="">
                  {t('My shopping list')}
                </a>
              </div>

              <SecondaryMenuItem>
                <LocationPin className={locationPinStyles} />
                <Link
                  to={routes.stores.path}
                  onClick={drawer.hideTarget}
                  className={linkStyles}
                >
                  {t('Find Store')}
                </Link>
              </SecondaryMenuItem>
            </SecondaryMenu>
            <Below breakpoint="sm">
              <ContentRenderer
                items={headerData}
                components={contactUspComponents}
              />
            </Below>
          </Scroll>
        </Drawer>
      )}
    </DrawerTarget>
  );
}
